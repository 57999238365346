import styled, { css } from "styled-components"

import { colors, getColor } from "../../theme"
import { SpacingProps, applySpacingProps } from "../../spacing"

type Props = {
  className?: string
  color?: keyof typeof colors
  size?: string
  align?: "left" | "center" | "right"
  fontWeight?: number
  fontStyle?: "normal" | "italic"
  inline?: boolean
  ellipsis?: boolean
  spacing?: SpacingProps
  grow?: boolean
  title?: string
  underline?: boolean | keyof typeof colors
  uppercase?: boolean

  children?: React.ReactNode
}

export const UIText: React.FC<Props> = styled.div.withConfig<Props>({
  shouldForwardProp: prop =>
    ![
      "color",
      "size",
      "align",
      "fontWeight",
      "fontStyle",
      "inline",
      "ellipsis",
      "spacing",
      "grow",
      "underline",
      "uppercase",
    ].includes(prop),
})`
  color: ${props => getColor(props.color ?? "black")};
  font-size: ${props => props.size ?? "12px"};
  font-weight: ${props => props.fontWeight ?? "normal"};
  font-style: ${props => props.fontStyle ?? "normal"};
  line-height: ${props => props.theme.lineHeight ?? "normal"};

  ${props =>
    props.align &&
    css`
      text-align: ${props.align};
    `}

  ${props =>
    props.inline &&
    css`
      display: inline;
    `}

  ${props =>
    props.grow &&
    css`
      flex-grow: 1;
    `}

  ${props =>
    props.ellipsis &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}

  ${props =>
    props.underline &&
    css`
      border-bottom: 1px solid ${getColor(props.color)};
    `}

  ${props =>
    props.uppercase &&
    css`
      text-transform: uppercase;
    `}

  ${props => applySpacingProps(props.spacing)};
`
