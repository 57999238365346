import React, { createContext, useEffect, useRef, useState } from "react"
import debounce from "lodash/debounce"

type ScreenDimensionContextType = {
  width: number | null
  height: number | null
}

export const WindowSizeContext = createContext<ScreenDimensionContextType>({
  width: null,
  height: null,
})

export const WindowSizeProvider: React.FC = ({ children }) => {
  const windowExists = typeof window !== "undefined"
  const docExists = typeof document !== "undefined"
  const [windowSize, setWindowSize] = useState({
    width: docExists ? document.documentElement.clientWidth : 0,
    height: docExists ? document.documentElement.clientHeight : 0,
  })

  const handleResize = () => {
    const doc = document.documentElement
    setWindowSize({
      width: doc.clientWidth,
      height: doc.clientHeight,
    })
  }
  const debouncedHandleResize = debounce(handleResize, 300)

  useEffect(() => {
    if (windowExists) window.addEventListener("resize", debouncedHandleResize)
    return () =>
      windowExists
        ? window.removeEventListener("resize", debouncedHandleResize)
        : null
  }, [])

  return (
    <WindowSizeContext.Provider value={windowSize}>
      {children}
    </WindowSizeContext.Provider>
  )
}
