import {
  VideoSlideProps,
  SlideOptionButtonProps,
  TextSlideProps,
  ProfileImageSlideProps,
} from "../components"

import behaSPhoProfil from "../../../assets/be-ha-s-pho-profil.png"
import tomasVagnerProfil from "../../../assets/tomas-vagner-profil.png"
import vnkucharkaWebsiteVideoPreview from "../../../assets/vnkucharka-website-video-preview.mp4"
import vtgymWebsiteVideoPreview from "../../../assets/vtgym-website-video-preview.mp4"

export const VIDEO_SLIDES: VideoSlideProps["videoSrc"][] = [
  vnkucharkaWebsiteVideoPreview,
  vtgymWebsiteVideoPreview,
  vnkucharkaWebsiteVideoPreview,
]

export const SLIDE_OPTIONS_BUTTONS: Pick<
  SlideOptionButtonProps,
  "background" | "imgSrc"
>[] = [
  {
    background: "rgba(220, 193, 170, 0.5)",
    imgSrc: behaSPhoProfil,
  },
  {
    background: "rgba(59, 64, 83, 0.17)",
    imgSrc: tomasVagnerProfil,
  },
  {
    background: "#B2C8DC",
    imgSrc: behaSPhoProfil,
  },
]

export const TEXT_SLIDES: TextSlideProps[] = [
  {
    title: "Be Ha alias Misa",
    titleDescription: "Youtuberka a blogerka",
    link: "vnkucharka.cz",
    description:
      "Na kluky jsem narazila na doporuceni. Skvela komunikace, komunikovali obratem a byli trpelivy ohledne meho nabiteho programu. Urcite se obratim na ne s dalsim projektem. 🙂",
  },
  {
    title: "Tomáš Vágner",
    titleDescription: "Majitel VTGYM posilovny",
    link: "vtgym.cz",
    description:
      "Věřím, že v novém VT GYM centru strávíte mnoho hezkých chvil a že Vaše odhodlání a motivace k pravidelnému cvičení se setká s Vaším osobním úspěchem. K tomu Vám přeji mnoho úspěchů.",
  },
  {
    title: "Prokop Dveře",
    titleDescription: "Youtuber a pranker",
    link: "itsaprank.com",
    description:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Phasellus et lorem id felis nonummy placerat. Mauris dictum facilisis augue. Proin mattis lacinia justo. Proin in tellus sit amet",
  },
]

export const PROFILE_IMAGE_SLIDES: Pick<
  ProfileImageSlideProps,
  "src" | "background"
>[] = [
  {
    background:
      "linear-gradient(180deg, rgba(220, 193, 170, 0) 22.4%, rgba(220, 193, 170, 0.5) 100%)",
    src: behaSPhoProfil,
  },

  {
    background:
      "linear-gradient(180deg, rgba(220, 193, 170, 0) 22.4%, rgba(59, 64, 83, 0.17) 100%)",
    src: tomasVagnerProfil,
  },
  {
    background:
      "linear-gradient(180deg, rgba(220, 193, 170, 0) 22.4%, rgba(178, 200, 220, 0.5) 100%)",
    src: behaSPhoProfil,
  },
]
