import React, { FC } from "react"
import styled, { css } from "styled-components"

import {
  ProcessPointerBackIcon,
  ProcessPointerIcon,
  SquareRounded14Icon,
  SquareRounded20Icon,
  SquareRounded50Icon,
  SquareRounded70Icon,
  VectorShapeToothSharp,
  VectorShapeToothSmooth,
  VectorShapeWorm,
} from "../../components/ui/icons"

export const WhatIsUXAndUCD: FC = () => {
  return (
    <Section id="what-is-ux-and-ucd">
      <CardWrap>
        <TopLeftSquare />

        <Card>
          <Heading>Co je to UX a UCD?</Heading>

          <DescriptionContainer>
            <SmallHeading>UX</SmallHeading>
            <Description>
              User-experience (UX) neboli Uzivatelska zkusenost je ...Tady
              potrebuji text, abych vedel jak to to nastylovat a pripadnou
              kompozici.
            </Description>
          </DescriptionContainer>
          <DescriptionContainer>
            <SmallHeading>UCD</SmallHeading>
            <Description>
              User-centered design (UCD) Tady potrebuji text, abych vedel jak to
              to nastylovat a pripadnou kompozici. Abych vedel jak to to
              nastylovat a pripadnou kompozici.
            </Description>
          </DescriptionContainer>

          <ProcessContainer>
            <StepWrap>
              <Button>
                <StepCircle>1</StepCircle>Analytics
              </Button>
              <ProcessPointerContainer>
                <ProcessPointerIcon />
                <HiddenProcessPointerIcon />
              </ProcessPointerContainer>
            </StepWrap>

            <StepWrap>
              <Button>
                <StepCircle>2</StepCircle>Design
              </Button>
              <ProcessPointerContainer>
                <ProcessPointerBackIcon />
                <ProcessPointerIcon />
              </ProcessPointerContainer>
            </StepWrap>

            <StepWrap>
              <Button>
                <StepCircle>3</StepCircle>Evaluation
              </Button>
              <ProcessPointerContainer>
                <ProcessPointerIcon />
                <ProcessPointerBackIcon />
              </ProcessPointerContainer>
            </StepWrap>

            <StepWrap>
              <Button>
                <StepCircle>4</StepCircle>Implementation
              </Button>
            </StepWrap>
          </ProcessContainer>

          <SquareFamily>
            <FirstFamilySquare />
            <SecondFamilySquare />
            <ThirdFamilySquare />
          </SquareFamily>
          <StyledVectorShapeWorm />
          <FirstVectorShapeToothSharp />
          <SecondVectorShapeToothSharp />
          <FirstVectorShapeToothSmooth />
          <SecondVectorShapeToothSmooth />
        </Card>
        <BottomRightSquare />
      </CardWrap>
    </Section>
  )
}

const Section = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 55px;
  overflow: hidden;

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    padding-top: 230px;
    padding-bottom: 280px;
  }
`

const CardWrap = styled.div`
  position: relative;
  margin: 0 ${props => props.theme.spaces.m};

  @media (min-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    width: 100%;
    max-width: 1075px;
    margin: 0 ${props => props.theme.spaces.xl};
  }
`

const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${props => props.theme.colors.deepVine};
  box-shadow: 0px 26px 60px -30px #5982ec;
  padding: 50px ${props => props.theme.spaces.l} 100px;
  border-radius: 20px;
  overflow: hidden;
  z-index: 3;

  @media (min-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    width: 100%;
  }
`

const Heading = styled.h2`
  margin-bottom: 50px;
  color: ${props => props.theme.colors.white};
  font-weight: 600;
  font-size: 30px;
  line-height: 110%;
  letter-spacing: -0.03em;

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    font-weight: bold;
    font-size: 53px;
    margin-bottom: ${props => props.theme.spaces.xl};
  }
`

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: ${props => props.theme.spaces.xl};
  }
`

const SmallHeading = styled.h3`
  width: 72px;
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 8px 14px -6px rgba(56, 40, 69, 0.25);
  border-radius: 10px;
  margin-bottom: ${props => props.theme.spaces.l};
  background: ${props => props.theme.colors.white};
  font-weight: bold;
  font-size: 25px;
  line-height: 120%;
  letter-spacing: -0.03em;
  color: ${props => props.theme.colors.darkVine};

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    margin-right: ${props => props.theme.spaces.xl};
  }
`

const Description = styled.p`
  max-width: 420px;
  text-align: center;
  color: ${props => props.theme.colors.white};
  font-weight: 600;
  font-size: 18px;
  line-height: 149%;
  letter-spacing: -0.01em;

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    margin-top: -4px;
    text-align: left;
  }
`

const ProcessContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    flex-direction: row;
    align-items: unset;
    margin: 50px 0 ${props => props.theme.spaces.xl};
  }
  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    margin-top: ${props => props.theme.spaces.xxl};
    margin-bottom: 0;
  }
`

const StepWrap = styled.div`
  position: relative;
  margin: 0 ${props => props.theme.spaces.m};
`

const StepCircle = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: ${props => props.theme.colors.white};
  box-shadow: 0px 5px 10px ${props => props.theme.colors.darkPurple};
  color: #6136b9;
  font-weight: bold;
  transform: translate(-50%, -50%);
`

const Button = styled.div`
  position: relative;
  min-width: 120px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${props => props.theme.spaces.m};
  padding-top: calc(${props => props.theme.spaces.m} + 2px);
  background: ${props => props.theme.colors.darkerPurple};
  box-shadow: 0px 8px 14px -5px #381b63;
  border-radius: 25px;
  color: ${props => props.theme.colors.white};
  font-size: 14px;
  font-weight: 600;
  user-select: none;
  cursor: default;
  z-index: 5;
`

const ProcessPointerContainer = styled.div`
  margin: -16px 0;
  transform: rotate(90deg);

  & > :nth-child(2) {
    transform: rotate(180deg) scaleX(-1);
  }

  @media (min-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    position: absolute;
    top: 50%;
    left: calc(50% + 4px);
    margin: 0;
    transform: translateY(-50%);
  }
`

const HiddenProcessPointerIcon = styled(ProcessPointerIcon)`
  visibility: hidden;
`

const TopLeftSquare = styled(SquareRounded70Icon)`
  display: none;

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    position: absolute;
    top: 0;
    left: 0;
    width: 480px;
    height: 480px;
    display: block;
    color: #f2f4fc;
    transform: translate(-100px, -100px) rotate(22.22deg);
    z-index: 2;
  }
`

const StyledVectorShapeWorm = styled(VectorShapeWorm)`
  display: none;

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    position: absolute;
    top: 320px;
    right: 280px;
    width: 927px;
    height: 564px;
    display: block;
    transform: translate(100%, -100%);
    z-index: 99;
  }
`

const SquareFamily = styled.div`
  display: none;

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 60px;
  }
`
const FirstFamilySquare = styled(SquareRounded14Icon)`
  width: 50px;
  height: 50px;
  margin-left: 75px;
  margin-bottom: 13.5px;
  color: rgba(242, 244, 252, 0.1);
  transform: rotate(71.14deg);
`
const SecondFamilySquare = styled(SquareRounded20Icon)`
  width: 90px;
  height: 90px;
  margin-left: 94px;
  margin-bottom: 16px;
  color: rgba(242, 244, 252, 0.1);
  transform: rotate(112.83deg);
`
const ThirdFamilySquare = styled(SquareRounded50Icon)`
  width: 200px;
  height: 200px;
  margin-left: -71px;
  color: rgba(242, 244, 252, 0.1);
  transform: rotate(84.51deg);
`

const backgroundWavesCSS = css`
  position: absolute;
  bottom: 0;
`
const FirstVectorShapeToothSharp = styled(VectorShapeToothSharp)`
  ${backgroundWavesCSS}
  opacity: 0.5;
  transform: translateY(76%) translateX(-20%);

  @media (min-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    left: 0;
    transform: translateY(calc(100% - 72px)) translateX(-102px);
  }
`
const SecondVectorShapeToothSharp = styled(VectorShapeToothSharp)`
  ${backgroundWavesCSS}
  transform: translateY(79%) translateX(-25%);

  @media (min-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    left: 0;
    transform: translateY(calc(100% - 51px)) translateX(-59px);
  }
`
const FirstVectorShapeToothSmooth = styled(VectorShapeToothSmooth)`
  ${backgroundWavesCSS}
  opacity: 0.9;
  transform: translateY(70%) translateX(40%);

  @media (min-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    right: 0;
    opacity: 0.5;
    transform: translateY(calc(100% - 67px)) translateX(112px);
  }
`
const SecondVectorShapeToothSmooth = styled(VectorShapeToothSmooth)`
  ${backgroundWavesCSS}
  transform: translateY(75%) translateX(47%);

  @media (min-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    right: 0;
    transform: translateY(calc(100% - 58px)) translateX(32px);
  }
`

const BottomRightSquare = styled(SquareRounded50Icon)`
  display: none;

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 350px;
    height: 350px;
    display: block;
    color: #f2f4fc;
    transform: translate(40px, 70px) rotate(-33.38deg);
    z-index: 2;
  }
`
