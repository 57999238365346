import React, { FC } from "react"
import styled from "styled-components"

type Props = {
  description: string
  Icon: FC
  title: string
}

export const PointItem: FC<Props> = ({ description, Icon, title }) => (
  <PointItemInner>
    <IconContainer>
      <Icon />
    </IconContainer>
    <Title>{title}</Title>
    <Description>{description}</Description>
  </PointItemInner>
)

const PointItemInner = styled.div`
  width: 100%;
  max-width: 325px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  letter-spacing: -0.01em;
`

const IconContainer = styled.div`
  min-width: 100px;
  max-width: 100px;
  min-height: 100px;
  max-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${props => props.theme.spaces.l};
  border-radius: 50%;
  background: ${props => props.theme.colors.white};
  box-shadow: 0px 6px 40px -15px rgba(107, 128, 244, 0.51);
`

const Title = styled.h3`
  margin-bottom: ${props => props.theme.spaces.m};
  color: ${props => props.theme.colors.dark};
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
`

const Description = styled.p`
  color: ${props => props.theme.colors.grayLight};
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
`
