import React from "react"
import styled from "styled-components"

import { buttonCSS } from "./styled/buttonCSS"

export type SlideOptionButtonProps = {
  background: string
  imgSrc: string
  isActive: boolean
  onClick: () => void
}

export const SlideOptionButton: React.FC<SlideOptionButtonProps> = props => {
  const { background, imgSrc, isActive, onClick } = props
  return (
    <Container onClick={onClick}>
      <Button background={background} isActive={isActive}>
        {isActive && <Border color={background} />}
        <Image src={imgSrc} />
      </Button>
    </Container>
  )
}

const Container = styled.div`
  ${buttonCSS}
  margin: 0 ${props => props.theme.spaces.m};
  overflow: visible;
`

const Button = styled.div<{ background: string; isActive: boolean }>`
  position: relative;
  width: 100%;
  height: 100%;
  background: ${props => props.background};
  border-radius: inherit;
  transition: transform ${props => props.theme.transitions.fastEase};
  transform: scale(${props => (props.isActive ? "1.2" : "1")});
  cursor: ${props => (props.isActive ? "default" : "pointer")};
`

const Border = styled.div<{ color: string }>`
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  height: 100%;
  box-sizing: content-box;
  border: 1px solid ${props => props.color};
  padding: 3px;
  z-index: 2;
  border-radius: inherit;
  transform: translate(-50%, -50%);
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: inherit;
`
