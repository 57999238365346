import React, { FC, useRef, useState } from "react"
import styled, { css } from "styled-components"

import { PlayTriangleIcon } from "../../../components/ui/icons"
import designThinkingProcessPoster from "../../../assets/design-thinking-process-poster.jpg"
import designThinkingProcessMP4 from "../../../assets/design-thinking-process.mp4"
import designThinkingProcessWEBM from "../../../assets/design-thinking-process.webm"

export const VideoContainer: FC = () => {
  const [displayControls, setDisplayControls] = useState(false)
  const videoRef = useRef<HTMLVideoElement>(null)

  return (
    <VideoContainerInner
      onClick={() => !displayControls && videoRef.current.play()}
    >
      <VideoOverlay displayControls={displayControls}>
        <DesktopHeading>Design Thinking Process</DesktopHeading>
        <DesktopDescription>
          aneb jak uvažujeme nad řešením problémů
        </DesktopDescription>
        <PlayButton>
          <PlayTriangleIcon />
        </PlayButton>
      </VideoOverlay>

      <Video
        poster={designThinkingProcessPoster}
        controls={displayControls}
        onPlay={() => setDisplayControls(true)}
        ref={videoRef}
      >
        <source src={designThinkingProcessWEBM} type="video/webm" />
        <source src={designThinkingProcessMP4} type="video/mp4" />
        Omlouváme se, ale Váš prohlížeč nepodporuje vložená videa.
      </Video>
    </VideoContainerInner>
  )
}

const VideoContainerInner = styled.div`
  position: relative;
  display: flex;
  max-width: 708px;
  border-radius: 15px;
  margin-bottom: ${props => props.theme.spaces.xxl};
  box-shadow: 0px 20px 40px -20px rgba(24, 70, 113, 0.4);
  cursor: pointer;
  overflow: hidden;

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    min-width: 708px;
    margin-bottom: 0;
  }
`

const VideoOverlay = styled.div<{ displayControls: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  border-radius: inherit;
  color: ${props => props.theme.colors.white};
  transition: ${props => props.theme.transitions.extraFastEase};
  pointer-events: none;

  ${props =>
    props.displayControls &&
    css`
      opacity: 0;
    `}

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    flex-direction: column;
    justify-content: flex-start;
  }
`

const DesktopHeading = styled.h2`
  display: none;

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    display: block;
    font-weight: 600;
    font-size: 40px;
    line-height: 55px;
    letter-spacing: -0.01em;
  }
`

const DesktopDescription = styled.p`
  display: none;

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    display: block;
    margin-bottom: ${props => props.theme.spaces.xxl};
    color: ${props => props.theme.colors.white};
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    letter-spacing: -0.01em;
  }
`

const PlayButton = styled.div`
  min-width: 80px;
  min-height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => props.theme.colors.white};
  border-radius: 50%;
  color: ${props => props.theme.colors.blueishPurple};
`

const Video = styled.video`
  width: 100%;
  height: auto;
  padding: 0;
  border: none;
  margin: 0;
`
