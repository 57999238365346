import React from "react"
import styled from "styled-components"
import Countdown from "react-countdown"

import { Providers } from "../components"
import Layout from "../components/layout"
import SEO from "../components/seo"

import {
  Hero,
  DesignThinkingProcess,
  References,
  WhatIsUXAndUCD,
  WhatWeDo,
  HappyClient,
  OurTechStack,
  SebIntroduction,
  OurWorkflow,
} from "../sections"
import {
  CircleIcon,
  SquareRounded10Icon,
  SquareRounded14Icon,
  SquareRounded20Icon,
  SquareRounded50Icon,
  SquareRounded70Icon,
  SquareRounded90Icon,
  VectorShapeTooth,
  VectorShapeToothSharp,
  VectorShapeToothSmooth,
  VectorShapeWave,
  VectorShapeWorm,
} from "../components/ui/icons"

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  const isOne = count => count === 1
  const isShort = count => count <= 4

  if (completed) {
    return (
      <>
        <br />
        Nový web se již nahrává
      </>
    )
  } else {
    return (
      <CountdownText>
        {days} {isOne(days) ? "Den" : isShort(days) ? "Dny" : "Dnů"}, {hours}{" "}
        {isOne(hours) ? "Hodina" : isShort(hours) ? "Hodiny" : "Hodin"},{" "}
        {minutes}{" "}
        {isOne(minutes) ? "Minuta" : isShort(minutes) ? "Minuty" : "Minut"},{" "}
        {seconds}{" "}
        {isOne(seconds) ? "Vteřina" : isShort(seconds) ? "Vteřiny" : "Vteřin"}
      </CountdownText>
    )
  }
}

const IndexPage = () => (
  <Providers>
    <Layout>
      <InPreparation>
        <InPrepBackground />
        <InPrepText>
          Připravujeme nový web
          <Countdown date={"2022/10/01 12:00:00"} renderer={renderer} />
        </InPrepText>
      </InPreparation>
      <SEO title="smoothweb" />
      <Hero />
      {/* <References />
      <WhatWeDo />
      <WhatIsUXAndUCD />
      <DesignThinkingProcess />
      <OurWorkflow />
      <HappyClient />
      <OurTechStack />
      <SebIntroduction /> */}

      {/* TODO: remove whole fake section after web is done */}
      {/* <FakeSection>
        VectorShapeTooth
        <VectorShapeTooth />
        VectorShapeToothSharp
        <VectorShapeToothSharp />
        VectorShapeToothSmooth
        <VectorShapeToothSmooth />
        VectorShapeWave
        <VectorShapeWave />
        VectorShapeWorm
        <VectorShapeWorm />
        CircleIcon
        <CircleIcon />
        SquareRounded10Icon
        <SquareRounded10Icon />
        SquareRounded14Icon
        <SquareRounded14Icon />
        SquareRounded20Icon
        <SquareRounded20Icon />
        SquareRounded50Icon
        <SquareRounded50Icon />
        SquareRounded70Icon
        <SquareRounded70Icon />
        SquareRounded90Icon
        <SquareRounded90Icon />
      </FakeSection> */}
    </Layout>
  </Providers>
)

export default IndexPage

const InPreparation = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 105px;
  z-index: 999;
  cursor: default;

  @media (min-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    padding-top: 0;
  }
`
const InPrepBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.8;
  animation: fadeInBackground 1s ease;

  @keyframes fadeInBackground {
    0% {
      opacity: 0.2;
    }
    100% {
      opacity: 0.8;
    }
  }
`

const InPrepText = styled.div`
  position: absolute;
  padding: 16px;
  text-align: center;
  color: white;
  border-radius: 30px;
  font-size: 26px;
  font-weight: 500;
  line-height: 28px;
  text-shadow: 0px 3px 6px rgb(0 0 0 / 15%);
  z-index: 500;
  opacity: 0.9;
  animation: fadeInText 2s ease;

  @keyframes fadeInText {
    0%,
    50% {
      opacity: 0;
    }
    100% {
      opacity: 0.9;
    }
  }

  @media (min-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    font-size: 48px;
    line-height: 58px;
  }
`
const CountdownText = styled.div`
  font-size: 16px;

  @media (min-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    font-size: 24px;
  }
`

const FakeSection = styled.div`
  width: 100%;
  min-height: 100vh;
  background: green;
  overflow: hidden;
`
