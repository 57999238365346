import { FC } from "react"

import {
  AnalyticsGraphIcon,
  DesktopAndMobileIcon,
  PuzzleComplementationIcon,
  SeoIcon,
  SmileOnMessageIcon,
  TachometerIcon,
} from "../../../components/ui/icons"

type PointItem = {
  Icon: FC
  title: string
  description: string
}
export const POINT_ITEMS: PointItem[] = [
  {
    Icon: PuzzleComplementationIcon,
    title: "Originální řešení na míru",
    description:
      "Řešení tvoříme v každém kroku vývoje kompletně na míru a to dle potřeb klientů a  vašemu businessu",
  },
  {
    Icon: AnalyticsGraphIcon,
    title: "Analytické nástroje",
    description:
      "Nasazujeme pokročilé analytické nástroje díky kterým dokážeme pochopit uživatele aplikace či návštěvníka webové stránky",
  },
  {
    Icon: SmileOnMessageIcon,
    title: "Úpravy dle zpětné vazby",
    description:
      "Na základě analýzy uživatelů webu, výzkumů a dalších zdrojů zpětné vazby web kontinuálně vylepšujeme",
  },
  {
    Icon: DesktopAndMobileIcon,
    title: "Responzivní design",
    description:
      "Samozřejmostí je funkčnost na všech zařízení od telefonu po 4k monitory stejně jako funkčnost na všech používaných prohlížečích",
  },
  {
    Icon: SeoIcon,
    title: "SEO",
    description:
      "Web optimalizujeme tak, aby se umísťoval ve výsledcích vyhledávačů co nejvýše - to zaručí značnou výhodu před konkurencí",
  },
  {
    Icon: TachometerIcon,
    title: "Rychlostní optimalizace",
    description:
      "Rychlost webu hraje roli při SEO, použitelnosti i míry opuštění webu. Optimalizujeme rychlost webu na maximum.",
  },
]
