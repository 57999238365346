import React, { FC } from "react"
import styled from "styled-components"

import { VideoContainer } from "./components"

import workingFigureOnALaptopWithShorts from "../../assets/working-figure-on-a-laptop-with-shorts.png"
import { SquareRounded90Icon } from "../../components/ui/icons"

export const DesignThinkingProcess: FC = () => {
  return (
    <section id="design-thinking-process">
      <Content>
        <Heading>Design Thinking Process</Heading>
        <Description>aneb jak uvažujeme nad řešením problémů</Description>

        <VideoContainer />

        <FigureContainer>
          <StyledSquareRounded90Icon />
          <FigureImage src={workingFigureOnALaptopWithShorts} />
        </FigureContainer>
      </Content>
    </section>
  )
}

const Content = styled.div`
  position: relative;
  width: 100%;
  max-width: 1340px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 ${props => props.theme.spaces.l};
  margin: 0 auto;

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    flex-direction: row;
    justify-content: flex-start;
  }
`

const Heading = styled.h2`
  margin-bottom: ${props => props.theme.spaces.l};
  text-align: center;
  font-weight: bold;
  font-size: 30px;
  line-height: 120%;

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    display: none;
  }
`

const Description = styled.p`
  margin-bottom: ${props => props.theme.spaces.l};
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: -0.01em;

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    display: none;
  }
`

const FigureContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 320px;
  height: auto;
  display: flex;
  margin-bottom: -160px;
  z-index: -1;

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    order: -1;
    align-self: flex-end;
    max-width: 480px;
    margin-right: ${props => props.theme.spaces.xl};
    margin-bottom: -60px;
  }

  @media (min-width: ${props => props.theme.deviceBreakpoints.bigTablet}) {
    margin-right: ${props => props.theme.spaces.xxl};
  }
`

const StyledSquareRounded90Icon = styled(SquareRounded90Icon)`
  display: none;
  @media (min-width: ${props => props.theme.deviceBreakpoints.bigTablet}) {
    display: block;
    position: absolute;
    top: -180px;
    left: -400px;
    color: ${props => props.theme.colors.lightBlue};
    z-index: -2;
  }
`

const FigureImage = styled.img`
  width: 100%;
  height: auto;
`
