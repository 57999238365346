import React from "react"
import styled from "styled-components"

export type HighlightedTextBlockProps = {
  color: string
  highlightedTitle: string
  title: string
  bulletItems?: string[]
  description?: string
}

export const HighlightedTextBlock = ({
  color,
  highlightedTitle,
  title,
  bulletItems,
  description,
}: HighlightedTextBlockProps) => (
  <HighlightedTextBlockInner $color={color}>
    <HighlightedTitle>{highlightedTitle}</HighlightedTitle>
    <Title>{title}</Title>
    {description && <Description>{description}</Description>}
    {bulletItems?.length > 0 && (
      <BulletsContainer>
        {bulletItems.map(item => (
          <BulletItem $color={color}>{item}</BulletItem>
        ))}
      </BulletsContainer>
    )}
  </HighlightedTextBlockInner>
)

const HighlightedTextBlockInner = styled.div<{
  $color: HighlightedTextBlockProps["color"]
}>`
  padding: 18px 24px;
  box-shadow: 0px 34px 30px -20px rgba(0, 0, 0, 0.15);
  border: 2px solid ${props => props.$color};
  border-radius: 4px;
  background: ${props => props.theme.colors.darkPurple};
  color: ${props => props.$color};
`

const HighlightedTitle = styled.p`
  margin-bottom: ${props => props.theme.spaces.xs};
  font-weight: 800;
  font-size: 14px;
  line-height: 19px;
  text-transform: uppercase;
`

const Title = styled.h3`
  margin-bottom: ${props => props.theme.spaces.l};
  color: ${props => props.theme.colors.white};
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: -0.01em;
`

const Description = styled.p`
  color: ${props => props.theme.colors.white};
  font-size: 16px;
  line-height: 22px;
`

const BulletsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
`

const BulletItem = styled.p<{ $color: HighlightedTextBlockProps["color"] }>`
  flex: 1 1 100%;
  display: inline-flex;
  align-items: flex-start;
  margin-bottom: ${props => props.theme.spaces.s};
  color: ${props => props.theme.colors.white};
  text-align: left;
  font-weight: 600;
  font-size: 14px;
  line-height: 149%;
  letter-spacing: -0.01em;

  &::before {
    content: "";
    min-width: 8px;
    max-width: 8px;
    min-height: 8px;
    max-height: 8px;
    margin-top: 6px;
    margin-right: ${props => props.theme.spaces.m};
    border-radius: 50%;
    background: ${props => props.$color};
  }

  @media (min-width: 450px) {
    flex: 1 1 50%;
  }
`
