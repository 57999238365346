import React from "react"
import styled from "styled-components"

export type TextSlideProps = {
  title: string
  titleDescription: string
  link: string
  description: string
}

export const TextSlide: React.FC<TextSlideProps> = ({
  title,
  titleDescription,
  link,
  description,
}) => {
  return (
    <TextSlideInner>
      <Title>{title}</Title>
      <TitleDescription>{titleDescription}</TitleDescription>
      <Link href={link} target="_blank">
        {link}
      </Link>
      <Description>{description}</Description>
    </TextSlideInner>
  )
}

const TextSlideInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  animation: ${props => props.theme.keyframes.fadeUp} ${props => props.theme.transitions.mediumEase};
`

const Title = styled.p`
  margin-bottom: ${props => props.theme.spaces.m};
  color: ${props => props.theme.colors.black};
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
`

const TitleDescription = styled.p`
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
`

const Link = styled.a`
  margin-bottom: ${props => props.theme.spaces.l};
  color: ${props => props.theme.colors.purpleVine};
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
`

const Description = styled.p`
  max-width: 345px;
  font-size: 16px;
  line-height: 22px;
`
