import React from "react"
import styled from "styled-components"

export type ProfileImageSlideProps = {
  background: string
  src: string
  className?: string
}

export const ProfileImageSlide: React.FC<ProfileImageSlideProps> = props => {
  const { background, src, className } = props
  return (
    <ProfileImageSlideInner className={className}>
      <Background background={background} />
      <ProfileImage src={src} />
    </ProfileImageSlideInner>
  )
}

const ProfileImageSlideInner = styled.div`
  position: relative;
  width: 290px;
  height: 290px;
  border-radius: 50%;
  margin-right: auto;
  margin-bottom: ${props => props.theme.spaces.l};
  margin-left: auto;
`

const Background = styled.div<Pick<ProfileImageSlideProps, "background">>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  overflow: hidden;
  background: ${props => props.background};
`

const ProfileImage = styled.img`
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
  z-index: 5;
`
