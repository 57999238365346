import React from "react"
import styled from "styled-components"

export type VideoSlideProps = {
  videoSrc: string
  onEnded: (event: React.SyntheticEvent<HTMLVideoElement, Event>) => void
}

export const VideoSlide: React.FC<VideoSlideProps> = ({
  videoSrc,
  onEnded,
}) => {
  return (
    <VideoSlideInner>
      <Video muted playsInline autoPlay onEnded={onEnded}>
        <source type="video/mp4" src={videoSrc} />
      </Video>
    </VideoSlideInner>
  )
}

const VideoSlideInner = styled.div`
  width: 100%;
  display: flex;
  border-radius: 10px;
  margin: 0 auto;
  animation: ${props => props.theme.keyframes.scale} ${props => props.theme.transitions.mediumEase};
  z-index: 2;
`

const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
  box-shadow: 0px 20px 40px -20px rgba(24, 70, 113, 0.4);
`
