import React, { useState, createElement, useContext } from "react"
import styled from "styled-components"
import Wave from "react-wavify"

import { HighlightedTextBlock } from "./components"
import { Button } from "../../components/ui/button"

import { WindowSizeContext } from "../../contexts"
import {
  WORKFLOW_MOBILE_CONTENT,
  WORKFLOW_DESKTOP_SLIDES,
  WEBSITE_CREATION_BULLETS,
  APP_PROTOTYPE_BULLETS,
} from "./constants"
import { colors } from "../../components/theme"

export const OurWorkflow = () => {
  const [slide, setSlide] = useState(3)

  const { width } = useContext(WindowSizeContext)
  const isMobile = width < 576

  return (
    <Section id="our-workflow">
      <StyledTopWave
        fill={colors.darkPurple}
        options={{
          height: isMobile ? 10 : 20,
          amplitude: isMobile ? 200 : 400,
          points: isMobile ? 1 : 2,
          speed: isMobile ? 0.32 : 0.17,
        }}
        paused
      />

      <SectionContent>
        <Title>Naše workflow</Title>

        <MobileContainer>
          {WORKFLOW_MOBILE_CONTENT.map(({ title, description }) => (
            <TextBlock>
              <BlockTitle>{title}</BlockTitle>
              <BlockDescription>{description}</BlockDescription>
            </TextBlock>
          ))}
        </MobileContainer>

        <DesktopContainer>
          <Slider>
            {WORKFLOW_DESKTOP_SLIDES.map((currSlide, index) => (
              <SliderItem
                className={index === slide ? "active" : undefined}
                isActive={index === slide}
                onClick={() => setSlide(index)}
              >
                <SliderNumber>{`0${index + 1}`}</SliderNumber>
                {currSlide.title}
              </SliderItem>
            ))}
          </Slider>

          <TextBlock key={`text-block-${slide}`}>
            <BlockTitle>{WORKFLOW_DESKTOP_SLIDES[slide].title}</BlockTitle>
            <BlockDescription>
              {WORKFLOW_DESKTOP_SLIDES[slide].description}
            </BlockDescription>
          </TextBlock>

          <SlideIconContainer key={`slide-icon-container-${slide}`}>
            {createElement(WORKFLOW_DESKTOP_SLIDES[slide].ImageComponent, {
              key: `workflow-desktop-slide-${slide}`,
            })}
          </SlideIconContainer>

          <StyledHighlightsContainer
            key={`styled-highlights-container-${slide}`}
          >
            {WORKFLOW_DESKTOP_SLIDES[slide].highlightTextBlock.map(props => (
              <HighlightedTextBlock {...props} />
            ))}
          </StyledHighlightsContainer>
        </DesktopContainer>

        <OutputTitle>Přehled výstupů z vývoje</OutputTitle>
        <HighlightsContainer $maxWidth="420px">
          <HighlightedTextBlock
            highlightedTitle="PŘEHLED výstupů"
            title="Tvorby webové stránky"
            bulletItems={WEBSITE_CREATION_BULLETS}
            color={colors.yellow}
          />
          <HighlightedTextBlock
            highlightedTitle="PŘEHLED výstupů"
            title="Tvorby prototypu aplikace"
            bulletItems={APP_PROTOTYPE_BULLETS}
            color={colors.orange}
          />
        </HighlightsContainer>

        <LureTitle>Jdeme na to?</LureTitle>
        <StyledA href="#seb-introduction">
          <Button color="white">Napište nám</Button>
        </StyledA>
      </SectionContent>

      <StyledBottomWave
        fill={colors.darkPurple}
        options={{
          height: isMobile ? 10 : 0,
          amplitude: 400,
          points: 2,
          speed: isMobile ? 0.09 : 0.2,
        }}
        paused
      />
    </Section>
  )
}

const Section = styled.section`
  margin-bottom: ${props => props.theme.spaces.xxl};

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    margin-bottom: ${props => props.theme.spaces.xxxl};
  }
`

const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${props => props.theme.spaces.l};
  padding-bottom: 0;
  background: ${props => props.theme.colors.darkPurple};
  color: ${props => props.theme.colors.white};

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    padding-top: ${props => props.theme.spaces.l};
  }
`

const StyledTopWave = styled(Wave)`
  position: relative;
  width: 100%;
  height: 100px;
  display: flex !important;
  transform: scaleX(-1) translateY(3px); /* translate to prevent white space between backgrounds */
  z-index: -1;

  @media (min-width: ${props => props.theme.deviceBreakpoints.mobile}) {
    height: 150px;
  }
`

const StyledBottomWave = styled(Wave)`
  position: relative;
  width: 100%;
  height: 120px;
  display: flex !important;
  transform: rotate(180deg) translateY(3px); /* translate to prevent white space between backgrounds */
  z-index: -1;

  @media (min-width: ${props => props.theme.deviceBreakpoints.mobile}) {
    height: 180px;
  }
`

const MobileContainer = styled.div`
  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    display: none;
  }
`

const Title = styled.h2`
  margin-bottom: ${props => props.theme.spaces.xxl};
  font-weight: 600;
  font-size: 30px;
  line-height: 110%;
  text-align: center;
  letter-spacing: -0.03em;

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    margin-bottom: 84px;
    font-size: 53px;
  }
`

const TextBlock = styled.div`
  max-width: 420px;
  margin-bottom: ${props => props.theme.spaces.xxl};
  text-align: center;
  z-index: 2;
  animation: ${props => props.theme.keyframes.opacity} 0.3s ease;

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    width: 100%;
    max-width: unset;
    margin-bottom: 45px;
    text-align: left;
  }
`

const BlockTitle = styled.h3`
  margin-bottom: ${props => props.theme.spaces.m};
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  letter-spacing: -0.01em;

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    margin-bottom: ${props => props.theme.spaces.xl};
    font-size: 40px;
    line-height: 55px;
  }
`

const BlockDescription = styled.p`
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.01em;

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    max-width: 420px;
    font-size: 20px;
    line-height: 27px;
  }
`

const SlideIconContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  pointer-events: none;
  animation: ${props => props.theme.keyframes.opacity} 0.5s ease;
`

const DesktopContainer = styled.div`
  display: none;

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    align-self: stretch;
    position: relative;
    width: 100%;
    max-width: 1440px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-right: ${props => props.theme.spaces.xxl};
    padding-left: 325px;
    margin: 0 auto;
    margin-bottom: ${props => props.theme.spaces.xxxl};
  }
`

const Slider = styled.div`
  position: absolute;
  top: 130px;
  left: ${props => props.theme.spaces.xxl};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 82px;
  user-select: none;
`

const SliderItem = styled.div<{ isActive: boolean }>`
  padding: ${props => props.theme.spaces.s} ${props => props.theme.spaces.m};
  margin: -${props => props.theme.spaces.s} -${props => props.theme.spaces.m};
  margin-bottom: calc(
    ${props => props.theme.spaces.l} - ${props => props.theme.spaces.s}
  );
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: -0.01em;
  color: ${props => props.theme.colors.white};
  opacity: ${props => (props.isActive ? "1" : "0.5")};
  cursor: pointer;
  transition: 0.15s ease;

  &:hover {
    opacity: 0.75;
  }

  &.active {
    animation: ${props => props.theme.keyframes.halfOpacity} 0.15s ease;

    &:hover {
      opacity: 1;
    }
  }
`

const SliderNumber = styled.span`
  margin-right: ${props => props.theme.spaces.l};
`

const OutputTitle = styled.h3`
  margin-bottom: ${props => props.theme.spaces.l};
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  letter-spacing: -0.01em;

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    font-size: 40px;
    line-height: 55px;
  }
`

const HighlightsContainer = styled.div<{ $maxWidth?: string }>`
  flex-basis: 100%;
  width: 100%;
  justify-content: center;
  display: grid;
  grid-gap: ${props => props.theme.spaces.l};
  grid-template-columns: repeat(
    auto-fit,
    minmax(300px, ${props => props.$maxWidth ?? "350px"})
  );
  margin-bottom: ${props => props.theme.spaces.xl};
  z-index: 2;

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    margin-bottom: ${props => props.theme.spaces.xxxl};
  }
`

const StyledHighlightsContainer = styled(HighlightsContainer)`
  justify-content: flex-start;
  margin-bottom: 0;
  animation: ${props => props.theme.keyframes.opacity} 0.4s ease;
`

const LureTitle = styled.p`
  margin-bottom: ${props => props.theme.spaces.l};
  color: ${props => props.theme.colors.white};
  font-weight: 600;
  font-size: 30px;
  line-height: 110%;
  letter-spacing: -0.03em;

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    margin-bottom: ${props => props.theme.spaces.xl};
  }
`

const StyledA = styled.a`
  border-radius: 34px;
`
