import styled, { css } from "styled-components"

import { colors, getColor } from "../../theme"
import { SpacingProps, applySpacingProps } from "../../spacing"

type Size =
  | "xxsmall"
  | "xsmall"
  | "small"
  | "regular"
  | "medium"
  | "large"
  | "xlarge"
  | "xxlarge"
  | "xxxlarge"
type Props = {
  as?: React.ElementType
  className?: string
  size?: Size
  color?: keyof typeof colors
  spacing?: SpacingProps

  children?: React.ReactNode
}

export const Text: React.FC<Props> = styled.p.withConfig<Props>({
  shouldForwardProp: prop => !["color", "spacing", "size"].includes(prop),
})`
  color: ${props => getColor(props.color ?? "black")};

  ${props => applySpacingProps(props.spacing)};

  ${props => props.size === "xlarge" && xlarge}
`

const xlarge = css`
  font-weight: 600;
  font-size: 30px;
  line-height: 110%;

  @media (min-width: ${props => props.theme.deviceBreakpoints.bigTablet}) {
    font-size: 53px;
  }
`
