import React from "react"
import styled from "styled-components"

type Props = {
  className?: string
}

export const ListItem: React.FC<Props> = ({ className, children }) => {
  return (
    <ListItemInner className={className}>
      <Bullet />
      <Text>{children}</Text>
    </ListItemInner>
  )
}

const ListItemInner = styled.div`
  display: flex;
  margin-bottom: ${props => props.theme.spaces.l};

  &:last-of-type {
    margin-bottom: 0;
  }
`

const Bullet = styled.div`
  position: relative;
  top: 5px;
  flex: 0 0 9px;
  width: 9px;
  height: 9px;
  margin-right: ${props => props.theme.spaces.m};
  background: ${props => props.theme.colors.vine};
  border-radius: 50%;

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    top: 8px;
  }
`

const Text = styled.p`
  color: ${props => props.theme.colors.dark};
  font-size: 14px;
  line-height: 149%;
  letter-spacing: -0.01em;

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    font-weight: 600;
    font-size: 18px;
  }
`
