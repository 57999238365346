import React, { FC } from "react"
import styled from "styled-components"

import { ListItem } from "./components"
import { Button } from "../../components/ui"
import { NoWrap } from "../../components/styled"
import computerWithBlocksOnBlob from "../../assets/computer-with-blocks-on-blob.jpg"
import womenOnRocketOnBlob from "../../assets/women-on-rocket-on-blob.jpg"

import { spaces } from "../../components/theme"

export const WhatWeDo: FC = () => (
  <OverflowContainer>
    <Section id="custom-web-creation">
      <ImageContainerLeft>
        <Image src={computerWithBlocksOnBlob} />
      </ImageContainerLeft>

      <Card>
        <Heading>
          Tvorba webových <NoWrap>stránek na míru</NoWrap>
        </Heading>

        <List>
          <StyledListItem>
            Vytvoříme vám profesionální webové stránky, které budou náležitě
            reprezentovat vaši firmu, službu či produkt.
          </StyledListItem>
          <StyledListItem>
            Webové stránky tvoříme pomocí nejnovějších technologií a výhradně na
            míru, abychom naplnili všechny vaše potřeby a především potřeby
            vašich klientů a tím maximalizovali váš profit.
          </StyledListItem>
          <StyledListItem>
            Váš web bude vyčnívat jak originalitou a designem tak technickým
            zpracováním. Díky tomu získáte konkurenční výhodu na trhu.
          </StyledListItem>
        </List>

        <ButtonHref href="#our-workflow">
          <Button>Jak vývoj probíhá?</Button>
        </ButtonHref>
      </Card>
    </Section>

    <CircleInCornerContainer>
      <CircleInCorner />
    </CircleInCornerContainer>

    <BottomSection id="custom-app-creation">
      <ImageContainerRight>
        <BottomImage src={womenOnRocketOnBlob} />
      </ImageContainerRight>

      <BottomCard>
        <Heading>
          Tvorba prototypu <NoWrap>aplikace na míru</NoWrap>
        </Heading>

        <List>
          <StyledListItem>
            Provedeme analýzu konkurence a patřičný uživatelský výzkum.
          </StyledListItem>
          <StyledListItem>
            Metodikou UCD (User-centered design) vytvoříme návrh aplikace od
            wireframe náčrtků přes design systém až po klikatelný prototyp
            aplikace.
          </StyledListItem>
          <StyledListItem>
            Následuje marketingové ověření úspěšnosti aplikace skrze tvorbu malé
            prezentační webové stránky (landing page) a adekvátních
            marketingových kampaní.
          </StyledListItem>
          <StyledListItem>
            Pokuď se na základě úspěšného prototypu aplikace rozhodnete nechat
            aplikaci naprogramovat, rádi pro váš vytvoříme frontend část
            aplikace. Následně vás můžeme předat do rukou odborníků na backend
            část aplikace neboli do jedné z našich partnerských firem, která
            aplikaci dokončí.
          </StyledListItem>
        </List>

        <ButtonHref href="#our-workflow">
          <Button>Jak vývoj probíhá?</Button>
        </ButtonHref>
      </BottomCard>
    </BottomSection>
  </OverflowContainer>
)

const OverflowContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
`

const Section = styled.section`
  width: 100%;
  max-width: 1340px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding-bottom: 90px;

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: 128px;
    padding-left: 128px;
  }
`

const BottomSection = styled(Section)`
  position: relative;

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    justify-content: flex-end;
    padding-bottom: 0;
  }
`

const CircleInCornerContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  right: 0;
  width: 100%;
  max-width: 1440px;
  display: flex;
  justify-content: flex-end;
  transform: translateX(-50%);
  pointer-events: none;
`
const CircleInCorner = styled.div`
  width: 264px;
  height: 264px;
  border: 75px solid ${props => props.theme.colors.lightBlue};
  border-radius: 50%;
  transform: translateX(calc(50% + 32px));
  z-index: 4;
`

const ButtonHref = styled.a`
  margin-top: ${props => props.theme.spaces.xl};
  border-radius: 34px;
`

const ImageContainer = styled.div`
  width: 100%;
  max-width: 380px;
  display: flex;
`
const ImageContainerLeft = styled(ImageContainer)`
  margin-bottom: -140px;
  margin-left: auto;
  padding-left: ${props => props.theme.spaces.s};

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    order: 2;
    margin-bottom: 0;
    margin-left: ${props => props.theme.spaces.l};
    padding-left: 0;
  }
  @media (min-width: ${props => props.theme.deviceBreakpoints.bigTablet}) {
    margin-left: ${props => props.theme.spaces.xl};
  }
`
const ImageContainerRight = styled(ImageContainer)`
  justify-content: flex-end;
  padding-right: ${props => props.theme.spaces.xl};
  margin-right: auto;
  margin-bottom: -180px;

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    margin-right: auto;
    padding-right: ${props => props.theme.spaces.xxl};
    margin-bottom: 0;
  }
`

const Image = styled.img`
  min-width: 560px;

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    min-width: 760px;
  }
  @media (min-width: ${props => props.theme.deviceBreakpoints.bigTablet}) {
    min-width: 977px;
  }
`
const BottomImage = styled(Image)`
  @media (min-width: ${props => props.theme.deviceBreakpoints.bigTablet}) {
    min-width: 891px;
  }
`

const Card = styled.div`
  align-self: center;
  position: relative;
  max-width: 420px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${props => props.theme.spaces.l};
  margin: ${props => props.theme.spaces.m};
  background: ${props => props.theme.colors.white};
  box-shadow: 0px 2px 19px -7px rgba(82, 101, 203, 0.4);
  border-radius: 8px;
  text-align: center;
  z-index: 3;

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    min-width: 494px;
    max-width: 588px;
    align-items: flex-start;
    padding: 0;
    margin: 0;
    text-align: left;
    box-shadow: none;
  }
`
const BottomCard = styled(Card)`
  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    max-width: 649px;
  }
`

const Heading = styled.h2`
  margin-bottom: ${props => props.theme.spaces.xl};
  color: ${props => props.theme.colors.vine};
  font-weight: bold;
  font-size: 30px;
  line-height: 120%;
  letter-spacing: -0.03em;

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    font-size: 53px;
    line-height: 110%;
  }
`

const List = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`

const StyledListItem = styled(ListItem)`
  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    margin-left: -15px;
  }
`
