import imageJavaScript from "../../../assets/tech-stack/JavaScript.jpeg"
import imageNodejs from "../../../assets/tech-stack/Nodejs.png"
import imageNGINX from "../../../assets/tech-stack/NGINX.png"
import imageReact from "../../../assets/tech-stack/React.png"
import imageHTML5 from "../../../assets/tech-stack/HTML5.png"
import imageMongoDB from "../../../assets/tech-stack/MongoDB.png"
import imageES6 from "../../../assets/tech-stack/ES6.jpg"
import imageTypeScript from "../../../assets/tech-stack/TypeScript.jpg"

import imageSass from "../../../assets/tech-stack/Sass.png"
import imageCSS3 from "../../../assets/tech-stack/CSS3.png"
import imageHeroku from "../../../assets/tech-stack/Heroku.png"
import imageRedux from "../../../assets/tech-stack/Redux.png"
import imageGraphQL from "../../../assets/tech-stack/GraphQL.png"
import imagestyledComponents from "../../../assets/tech-stack/styled-components.png"
import imageMobX from "../../../assets/tech-stack/MobX.png"
import imageCloudinary from "../../../assets/tech-stack/Cloudinary.png"

type TechStackItem = {
  imageSrc: string
  title: string
}
export const TECH_STACK_ITEMS: TechStackItem[] = [
  {
    imageSrc: imageJavaScript,
    title: "JavaScript",
  },
  {
    imageSrc: imageNodejs,
    title: "Nodejs",
  },
  {
    imageSrc: imageNGINX,
    title: "NGINX",
  },
  {
    imageSrc: imageReact,
    title: "React",
  },
  {
    imageSrc: imageHTML5,
    title: "HTML5",
  },
  {
    imageSrc: imageMongoDB,
    title: "MongoDB",
  },
  {
    imageSrc: imageES6,
    title: "ES6",
  },
  {
    imageSrc: imageTypeScript,
    title: "TypeScript",
  },
  {
    imageSrc: imageSass,
    title: "Sass",
  },
  {
    imageSrc: imageCSS3,
    title: "CSS3",
  },
  {
    imageSrc: imageHeroku,
    title: "Heroku",
  },
  {
    imageSrc: imageRedux,
    title: "Redux",
  },
  {
    imageSrc: imageGraphQL,
    title: "GraphQL",
  },
  {
    imageSrc: imagestyledComponents,
    title: "styled-components",
  },
  {
    imageSrc: imageMobX,
    title: "MobX",
  },
  {
    imageSrc: imageCloudinary,
    title: "Cloudinary",
  },
]
