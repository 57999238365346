import React, { useContext } from "react"
import styled from "styled-components"
import Wave from "react-wavify"

import { Text } from "../../components/ui"

import { spaces } from "../../components/theme"
import { NoWrap } from "../../components/styled"

import tabletPreviewVtgym from "../../assets/tablet-preview-vtgym.png"
import tabletPreviewVnkucharka from "../../assets/tablet-preview-vnkucharka.png"
import tabletPreviewGardenAndKitchen from "../../assets/tablet-preview-garden-and-kitchen.png"
import {
  VectorShapeTooth,
  VectorShapeWave,
  VectorShapeWorm,
  VectorTabletDotPattern,
  IconTabletSelection,
} from "../../components/ui/icons"

import { WindowSizeContext } from "../../contexts"

export const Hero = () => {
  const { width } = useContext(WindowSizeContext)
  const isMobile = width < 576

  return (
    <HeroSection id="hero">
      <StyledWave
        fill="white"
        paused={false}
        options={{
          height: isMobile ? 100 : 40,
          amplitude: isMobile ? 30 : 70,
          speed: 0.15,
          points: isMobile ? 2 : 3,
        }}
      />
      <HeroContainerBackground />
      <StyledVectorShapeWave />
      <StyledVectorShapeTooth />
      <StyledVectorShapeWorm />

      <TextContainer>
        <MainTitle color="white" size="xlarge" spacing={{ mb: spaces.l }}>
          Tvorba webových stránek <NoWrap>a prototypů</NoWrap> aplikací na míru
        </MainTitle>
        <TitleDescription color="white" spacing={{ mb: spaces.l }}>
          Specializujeme se na vizuální část webových stránek (Frontend) a
          uživatelskou zkušenost (UX)
        </TitleDescription>
        <Hashtags color="white" spacing={{ mb: spaces.l }}>
          #Frontend #UserExperienceDesign #LetsWorkTogether
        </Hashtags>
      </TextContainer>

      <TabletContainer>
        <StyledIconTabletSelection />
        <StyledVectorTabletDotPattern />

        <TabletImg src={tabletPreviewGardenAndKitchen} />
        <TabletImgSecond src={tabletPreviewVtgym} />
        <TabletImgThird src={tabletPreviewVnkucharka} />
      </TabletContainer>
    </HeroSection>
  )
}

const HeroSection = styled.section`
  position: relative;
  max-width: 100vw;
  min-height: 568px;
  height: 85vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  @media (min-width: ${props => props.theme.deviceBreakpoints.bigTablet}) {
    height: 100vh;
    flex-direction: row;
    justify-content: center;
  }
`

const StyledWave = styled(Wave)`
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  display: flex !important;
  z-index: 29;
`

const HeroContainerBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(247.26deg, #4771dc 22.21%, #6224bd 75.1%);
  z-index: -2;
`
const StyledVectorShapeWave = styled(VectorShapeWave)`
  position: absolute;
  top: 0;
  right: 630px;
  z-index: -1;
`
const StyledVectorShapeTooth = styled(VectorShapeTooth)`
  position: absolute;
  top: 0;
  right: 560px;
  z-index: -1;
`
const StyledVectorShapeWorm = styled(VectorShapeWorm)`
  position: absolute;
  top: 50px;
  left: -350px;
  pointer-events: none;
  z-index: -1;
`

const TextContainer = styled.div`
  box-sizing: border-box;
  padding: 0 ${props => props.theme.spaces.l};
  padding-top: 100px;
  text-align: center;

  @media (min-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    padding-top: ${props => props.theme.spaces.xxl};
  }

  @media (min-width: ${props => props.theme.deviceBreakpoints.bigTablet}) {
    order: 2;
    padding-top: 0;
    padding-left: ${props => props.theme.spaces.xl};
    margin-bottom: ${props => props.theme.spaces.xxl};
    text-align: left;
  }

  @media (min-width: ${props => props.theme.deviceBreakpoints.laptop}) {
    padding-left: ${props => props.theme.spaces.xxl};
  }
`

const MainTitle = styled(Text)`
  max-width: 420px;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: ${props => props.theme.deviceBreakpoints.bigTablet}) {
    max-width: 700px;
    margin-right: unset;
    margin-left: unset;
  }
`

const TitleDescription = styled(Text)`
  max-width: 350px;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: ${props => props.theme.deviceBreakpoints.mobile}) {
    max-width: 420px;
  }

  @media (min-width: ${props => props.theme.deviceBreakpoints.bigTablet}) {
    max-width: 710px;
    margin-right: unset;
    margin-left: unset;
  }
`

const Hashtags = styled(Text)`
  display: none;

  @media (min-width: ${props => props.theme.deviceBreakpoints.mobile}) {
    display: block;
  }
`

const TabletContainer = styled.div`
  position: relative;
  min-width: 260px;
  width: 60%;
  max-width: 370px;
  padding: 0 10px;
  margin: 0 ${props => props.theme.spaces.l};
  margin-top: ${props => props.theme.spaces.xl};

  @media (min-width: ${props => props.theme.deviceBreakpoints.bigTablet}) {
    padding: 0;
    margin-top: 0;
    margin-right: ${props => props.theme.spaces.xxl};
    margin-left: unset;
  }
`

const TabletImg = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  height: auto;
  opacity: 1;
  z-index: 1;
`
const TabletImgSecond = styled(TabletImg)`
  opacity: 0;
  z-index: 2;
  animation: tabletFade1 12s linear infinite;

  @keyframes tabletFade1 {
    0%,
    29% {
      opacity: 0;
    }
    33%,
    66% {
      opacity: 1;
    }
    67%,
    100% {
      opacity: 0;
    }
  }
`
const TabletImgThird = styled(TabletImg)`
  opacity: 0;
  z-index: 3;
  animation: tabletFade2 12s linear infinite;

  @keyframes tabletFade2 {
    0%,
    62% {
      opacity: 0;
    }
    66%,
    95% {
      opacity: 1;
    }
    96%,
    100% {
      opacity: 0;
    }
  }
`

const StyledIconTabletSelection = styled(IconTabletSelection)`
  position: relative;
  top: 0;
  left: 15%;
  width: 100%;
  height: auto;
  z-index: 9;
  transform: translate(-6%, -20px);
`

const StyledVectorTabletDotPattern = styled(VectorTabletDotPattern)`
  position: absolute;
  top: 0;
  right: 0;
  width: 30%;
  max-width: 90px;
  transform: translate(-10%, -20%) scale(0.7);
  pointer-events: none;
  z-index: -1;

  @media (min-width: ${props => props.theme.deviceBreakpoints.bigTablet}) {
    transform: translate(-30%, -30%);
  }
`
