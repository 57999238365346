import React, { FC } from "react"
import styled from "styled-components"

import { PointItem } from "./components"
import ourHappyClient from "../../assets/our-happy-client.png"

import { POINT_ITEMS } from "./constants/constants"
import { spaces } from "../../components/theme"

export const HappyClient: FC = () => (
  <Section id="happy-client">
    <SectionContent>
      <CircleIconsContainer>
        <SmallCircle />
        <BigCircle />
      </CircleIconsContainer>

      <HeroContainer>
        <TextContainer>
          <Heading>
            <NoWrap>Vše se točí kolem</NoWrap>{" "}
            <NoWrap>spokojeného uživatele</NoWrap>
          </Heading>
          <Description>
            Úspěšnost webové stránky či aplikace se odvíjí od zkušenosti, kterou
            s ní uživatelé mají. Proto cílíme na dosažení spokojenosti uživatele
            ve všech aspektech webu.
          </Description>
        </TextContainer>
        <ImageContainer>
          <ImageHappyClient src={ourHappyClient} />
        </ImageContainer>
      </HeroContainer>

      <PointItemsContainer>
        {POINT_ITEMS.map(({ Icon, title, description }, i) => (
          <PointItem
            key={i}
            Icon={Icon}
            title={title}
            description={description}
          />
        ))}
      </PointItemsContainer>
    </SectionContent>
  </Section>
)

const Section = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 ${props => props.theme.spaces.l};
  margin: 0 auto ${props => props.theme.spaces.xxxl};
  overflow: hidden;

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    padding: 0 ${props => props.theme.spaces.xl};
  }
`

const SectionContent = styled.div`
  position: relative;
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const CircleIconsContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-41%);
  z-index: -1;
`
const BigCircle = styled.div`
  width: 334px;
  height: 334px;
  border: 40px solid ${props => props.theme.colors.lightBlue};
  border-radius: 50%;

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    width: 814px;
    height: 814px;
    border-width: 120px;
  }
`

const SmallCircle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 169px;
  height: 169px;
  border: 40px solid ${props => props.theme.colors.lightBlue};
  border-radius: 50%;
  transform: translate(-50%, -50%);

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    width: 411px;
    height: 411px;
    border-width: 120px;
  }
`

const HeroContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    flex-direction: row;
    margin-bottom: ${props => props.theme.spaces.xxl};
  }
`

const TextContainer = styled.div`
  max-width: 524px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    margin-top: ${props => props.theme.spaces.xl};
    text-align: left;
  }
`

const Heading = styled.h2`
  max-width: 524px;
  margin-bottom: ${props => props.theme.spaces.l};
  color: ${props => props.theme.colors.vine};
  font-weight: 600;
  font-size: 30px;
  line-height: 110%;
  letter-spacing: -0.03em;

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    font-size: 53px;
  }
`

const Description = styled.h2`
  margin-bottom: ${props => props.theme.spaces.xl};
  color: ${props => props.theme.colors.dark};
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.01em;

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    margin-bottom: 0;
    font-size: 20px;
    line-height: 27px;
  }
`

const ImageContainer = styled.div``

const ImageHappyClient = styled.img`
  width: 100%;
  max-width: 598px;
  height: auto;
  margin-bottom: ${props => props.theme.spaces.xl};
  object-fit: contain;

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    margin-bottom: 0;
  }
`

const NoWrap = styled.span`
  white-space: nowrap;
`

const pointItemSpace = spaces.xl
const PointItemsContainer = styled.div`
  width: 100%;
  max-width: calc((325px + ${pointItemSpace}) * 3);
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(295px, 325px));
  justify-content: center;
  grid-gap: ${props => props.theme.spaces.xxl} ${pointItemSpace};

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    grid-gap: 80px ${pointItemSpace};
  }
`
