import React, { useState } from "react"
import styled from "styled-components"
import { TriangleIcon } from "../ui/icons"

import {
  ProfileImageSlide,
  SlideOptionButton,
  SliderButton,
  TextSlide,
  VideoSlide,
} from "./components"

import {
  PROFILE_IMAGE_SLIDES,
  SLIDE_OPTIONS_BUTTONS,
  TEXT_SLIDES,
  VIDEO_SLIDES,
} from "./constants"

export const Slider = () => {
  const [activeSlide, setActiveSlide] = useState(0)

  const activeVideoSlide = VIDEO_SLIDES[activeSlide]
  const activeTextSlide = TEXT_SLIDES[activeSlide]
  const activeProfileImageSlide = PROFILE_IMAGE_SLIDES[activeSlide]
  const slidesCount = SLIDE_OPTIONS_BUTTONS.length

  const handlePreviousSlide = () => {
    const isFirstSlide = activeSlide === 0
    setActiveSlide((isFirstSlide ? slidesCount : activeSlide) - 1)
  }
  const handleNextSlide = () => {
    const isLastSlide = activeSlide === slidesCount - 1
    setActiveSlide(isLastSlide ? 0 : activeSlide + 1)
  }

  return (
    <SliderInner>
      <DesktopSliderButton side="left" onClick={handlePreviousSlide} />
      {/* TODO: Add video container with aspect ratio of videos which will always occupy height so it won't trigger re-paint/reflow */}
      <VideoContainer>
        <StyledTriangleIcon />
        <VideoSlide
          key={`video-slide-${activeSlide}`}
          videoSrc={activeVideoSlide}
          onEnded={handleNextSlide}
        />
      </VideoContainer>

      <MobileSliderUI>
        <SliderButton side="left" onClick={handlePreviousSlide} />
        {SLIDE_OPTIONS_BUTTONS.map((slideOptionButton, i) => (
          <SlideOptionButton
            key={`slide-option-button-${i}`}
            background={slideOptionButton.background}
            imgSrc={slideOptionButton.imgSrc}
            isActive={activeSlide === i}
            onClick={() => setActiveSlide(i)}
          />
        ))}
        <SliderButton side="right" onClick={handleNextSlide} />
      </MobileSliderUI>

      <TextContainer>
        <DesktopProfileImageSlide
          key={`profile-image-slide-${activeSlide}`}
          background={activeProfileImageSlide.background}
          src={activeProfileImageSlide.src}
        />
        <TextSlide
          key={`text-slide-${activeSlide}`}
          title={activeTextSlide.title}
          titleDescription={activeTextSlide.titleDescription}
          link={activeTextSlide.link}
          description={activeTextSlide.description}
        />
        <DesktopSliderUI>
          {SLIDE_OPTIONS_BUTTONS.map((slideOptionButton, i) => (
            <SlideOptionButton
              key={`slide-option-button-${i}`}
              background={slideOptionButton.background}
              imgSrc={slideOptionButton.imgSrc}
              isActive={activeSlide === i}
              onClick={() => setActiveSlide(i)}
            />
          ))}
        </DesktopSliderUI>
      </TextContainer>
      <DesktopSliderButton side="right" onClick={handleNextSlide} />
    </SliderInner>
  )
}

const SliderInner = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${props => props.theme.deviceBreakpoints.bigTablet}) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
`

const VideoContainer = styled.div`
  position: relative;
  align-self: center;
  max-width: 500px;
  display: flex;
  margin-bottom: ${props => props.theme.spaces.xl};

  @media (min-width: ${props => props.theme.deviceBreakpoints.bigTablet}) {
    flex: 1;
    max-width: unset;
    margin-right: ${props => props.theme.spaces.xl};
  }
`

const StyledTriangleIcon = styled(TriangleIcon)`
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(calc(-50% + 120px), calc(-50% + 140px))
    rotate(-162.67deg);
  color: ${props => props.theme.colors.lightBlue};
  z-index: 0;

  @media (min-width: ${props => props.theme.deviceBreakpoints.bigTablet}) {
    display: block;
  }
`

const SliderUI = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${props => props.theme.spaces.xl};

  @media (min-width: ${props => props.theme.deviceBreakpoints.bigTablet}) {
    order: 3;
    flex: 1 0 100%;
    margin-bottom: 0;
  }
`
const MobileSliderUI = styled(SliderUI)`
  @media (min-width: ${props => props.theme.deviceBreakpoints.bigTablet}) {
    display: none;
  }
`
const DesktopSliderUI = styled(SliderUI)`
  display: none;

  @media (min-width: ${props => props.theme.deviceBreakpoints.bigTablet}) {
    display: flex;
    margin-top: ${props => props.theme.spaces.xl};
  }
`

const TextContainer = styled.div`
  z-index: 2;

  @media (min-width: ${props => props.theme.deviceBreakpoints.bigTablet}) {
    margin-bottom: ${props => props.theme.spaces.xl};
  }
`

const DesktopProfileImageSlide = styled(ProfileImageSlide)`
  display: none;

  @media (min-width: ${props => props.theme.deviceBreakpoints.bigTablet}) {
    display: block;
  }
`

const DesktopSliderButton = styled(SliderButton)`
  display: none;
  margin-left: ${props =>
    props.side === "left" ? "0" : props.theme.spaces.xxl};
  margin-right: ${props =>
    props.side === "right" ? "0" : props.theme.spaces.xxl};

  @media (min-width: ${props => props.theme.deviceBreakpoints.bigTablet}) {
    display: flex;
    align-self: center;
  }
`
