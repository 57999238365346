import { FC } from "react"
import { colors } from "../../../components/theme"
import {
  SlideAnalysisOnChart,
  SlideCardsAndChart,
  SlideCardsAndCurlyBraces,
  SlideCardsAndPalette,
  SlideCardsAndPerson,
  SlideCardsAndReaction,
  SlideCardsAndRightTriangle,
  SlideCloudAndServer,
} from "../../../components/ui/icons"
import { HighlightedTextBlockProps } from "../components/HighlightedTextBlock"

type WorkflowSlide = {
  title: string
  description: string
  ImageComponent: FC
  highlightTextBlock: HighlightedTextBlockProps[]
}

export const WORKFLOW_DESKTOP_SLIDES: WorkflowSlide[] = [
  {
    title: "Sběr požadavků",
    description:
      "Eu consequat ipsum quis eiusmod fugiat sint ut consequat aliquip ullamco magna. Quis cillum in voluptate magna est ullamco duis cillum. Culpa id Lorem cupidatat amet do irure irure dolore elit qui consequat.",
    ImageComponent: SlideCardsAndPerson,
    highlightTextBlock: [
      {
        color: colors.yellow,
        highlightedTitle: "htb_HIGHLIGHTED_TITLE",
        title: "htb_TITLE",
        description:
          "Fugiat sunt adipisicing ullamco proident irure reprehenderit est sunt in. Ad laboris minim laboris laborum commodo dolore adipisicing aute qui officia non esse. Ad ipsum proident ullamco exercitation incididunt ut irure commodo mollit nulla Lorem.",
      },
      {
        color: colors.orange,
        highlightedTitle: "htb_HIGHLIGHTED_TITLE_2",
        title: "htb_TITLE_2",
        description:
          "Adipisicing duis sunt deserunt aliqua commodo ex pariatur sit dolore est enim. Quis laboris irure veniam sit esse non enim tempor labore anim id occaecat laboris. Laboris sit velit officia magna sunt ex nostrud officia sit consequat.",
      },
    ],
  },
  {
    title: "Výzkum",
    description:
      "Ullamco veniam enim in sit ad nulla Lorem id eu culpa sint. Reprehenderit adipisicing reprehenderit elit aliqua quis exercitation consectetur ad voluptate. Occaecat quis officia eiusmod in proident.",
    ImageComponent: SlideCardsAndChart,
    highlightTextBlock: [
      {
        color: colors.yellow,
        highlightedTitle: "htb_HIGHLIGHTED_TITLE",
        title: "htb_TITLE",
        description:
          "Eiusmod nisi nostrud proident do. Magna reprehenderit quis ut est nulla tempor in minim consequat consequat irure. Voluptate aute id dolore ullamco reprehenderit eiusmod.",
      },
      {
        color: colors.orange,
        highlightedTitle: "htb_HIGHLIGHTED_TITLE_2",
        title: "htb_TITLE_2",
        description:
          "Exercitation consequat veniam reprehenderit non ad do aliqua eu laboris aliquip. Consectetur consequat sunt quis occaecat nostrud anim qui nisi. Tempor velit do ipsum ut veniam non esse duis nostrud duis.",
      },
    ],
  },
  {
    title: "Visual ID",
    description:
      "Culpa quis anim Lorem duis ipsum incididunt culpa enim ea. Exercitation Lorem velit laborum velit. Labore aliquip eiusmod Lorem fugiat duis.",
    ImageComponent: SlideCardsAndReaction,
    highlightTextBlock: [
      {
        color: colors.yellow,
        highlightedTitle: "htb_HIGHLIGHTED_TITLE",
        title: "htb_TITLE",
        description:
          "In laboris reprehenderit occaecat anim veniam ad sit commodo exercitation. Non deserunt mollit veniam qui eiusmod et fugiat et velit. Voluptate esse eu id mollit aute quis non in nulla commodo.",
      },
      {
        color: colors.orange,
        highlightedTitle: "htb_HIGHLIGHTED_TITLE_2",
        title: "htb_TITLE_2",
        description:
          "Elit incididunt voluptate anim dolor ex proident mollit consectetur et nulla Lorem tempor sint. Ut sunt aliqua magna Lorem qui veniam nostrud enim nostrud ullamco velit. Qui in ea commodo in magna amet aliquip consequat.",
      },
    ],
  },
  {
    title: "UX Design",
    description:
      "Aute fugiat cupidatat exercitation minim in amet tempor nostrud laborum nostrud velit minim in. Proident consectetur duis elit aute non anim fugiat exercitation cupidatat adipisicing. Elit cillum pariatur laborum aliquip.",
    ImageComponent: SlideCardsAndRightTriangle,
    highlightTextBlock: [
      {
        color: colors.yellow,
        highlightedTitle: "Jaky vystup ocekavat u:",
        title: "Tvorba webovych stranek",
        description:
          "Pokud nesnasite kafe platite nam pivo to je motto starteru projektu. Vystupem bude tak napul hotva klikacka.",
      },
      {
        color: colors.orange,
        highlightedTitle: "Jaky vystup ocekavat u:",
        title: "Nastartujeme vas projekt",
        description:
          "Pokud nesnasite kafe platite nam pivo to je motto starteru projektu. Vystupem bude tak napul hotva klikacka.Pokud nesnasite kafe platite nam pivo to je motto starteru projektu. Vystupem bude tak napul hotva klikacka.",
      },
    ],
  },
  {
    title: "UI Design",
    description:
      "Pariatur quis qui pariatur consectetur quis aliqua. Occaecat dolor minim pariatur laborum irure irure officia. Exercitation dolor ut excepteur ex deserunt laborum nostrud consequat do cillum incididunt incididunt.",
    ImageComponent: SlideCardsAndPalette,
    highlightTextBlock: [
      {
        color: colors.yellow,
        highlightedTitle: "htb_HIGHLIGHTED_TITLE",
        title: "htb_TITLE",
        description:
          "Esse aliqua irure in occaecat consectetur fugiat deserunt cillum. Culpa et nostrud cillum pariatur. Amet mollit eu labore in officia et consequat laboris esse aliqua nulla.",
      },
      {
        color: colors.orange,
        highlightedTitle: "htb_HIGHLIGHTED_TITLE_2",
        title: "htb_TITLE_2",
        description:
          "Qui mollit ullamco labore fugiat ea et adipisicing. Incididunt cillum irure incididunt ex duis. Veniam qui elit incididunt sint excepteur ullamco.",
      },
    ],
  },
  {
    title: "Realizace",
    description:
      "Consequat ex enim exercitation velit mollit excepteur ad ad incididunt cupidatat aliqua. Et dolore amet aute id est in ullamco. Adipisicing aliquip velit exercitation anim ut eiusmod sint ex dolore et.",
    ImageComponent: SlideCardsAndCurlyBraces,
    highlightTextBlock: [
      {
        color: colors.yellow,
        highlightedTitle: "htb_HIGHLIGHTED_TITLE",
        title: "htb_TITLE",
        description:
          "In aute ut ea ut nisi laboris dolor laboris duis in esse duis deserunt ut. Officia ullamco culpa aliqua est aliqua tempor reprehenderit velit. Nulla veniam ea fugiat fugiat esse cillum incididunt proident culpa magna dolore nisi.",
      },
      {
        color: colors.orange,
        highlightedTitle: "htb_HIGHLIGHTED_TITLE_2",
        title: "htb_TITLE_2",
        description:
          "Amet magna occaecat voluptate do quis. Anim fugiat culpa ut occaecat pariatur velit ex fugiat qui duis occaecat officia velit officia. Ex eu fugiat ex sit dolore.",
      },
    ],
  },
  {
    title: "Měření výkonu",
    description:
      "Proident dolore reprehenderit anim est. Sunt anim eu anim et. Exercitation tempor occaecat mollit adipisicing ut exercitation.",
    ImageComponent: SlideAnalysisOnChart,
    highlightTextBlock: [
      {
        color: colors.yellow,
        highlightedTitle: "htb_HIGHLIGHTED_TITLE",
        title: "htb_TITLE",
        description:
          "Sint officia cillum fugiat veniam eu aute in. Cillum ea reprehenderit nostrud ad ex. Eiusmod ut occaecat magna proident enim.",
      },
      {
        color: colors.orange,
        highlightedTitle: "htb_HIGHLIGHTED_TITLE_2",
        title: "htb_TITLE_2",
        description:
          "Ipsum velit laborum enim in proident ex anim voluptate id Lorem velit labore ut. Velit pariatur aliquip aliqua qui incididunt laborum adipisicing cupidatat. Ex eiusmod amet duis ex officia magna.",
      },
    ],
  },
  {
    title: "Optimalizace",
    description:
      "Proident et velit magna ex elit ad adipisicing exercitation tempor non deserunt non. Voluptate eiusmod sint quis officia sit laboris do ad officia anim nulla amet. Quis sit id minim dolor dolor commodo.",
    ImageComponent: SlideCloudAndServer,
    highlightTextBlock: [
      {
        color: colors.yellow,
        highlightedTitle: "htb_HIGHLIGHTED_TITLE",
        title: "htb_TITLE",
        description:
          "Nostrud nisi mollit minim quis excepteur nulla do qui fugiat ex do consectetur. Eiusmod elit eiusmod cupidatat et eiusmod eiusmod labore Lorem minim occaecat reprehenderit proident sint sit. Sint laborum duis enim ea fugiat ullamco cillum.",
      },
      {
        color: colors.orange,
        highlightedTitle: "htb_HIGHLIGHTED_TITLE_2",
        title: "htb_TITLE_2",
        description:
          "Elit esse nisi in proident duis commodo veniam reprehenderit cillum. Aliqua non labore magna non quis in excepteur anim magna commodo. Enim magna eiusmod et in veniam pariatur aliquip cupidatat dolor voluptate sunt aliqua dolore proident.",
      },
    ],
  },
]
