import React, { FC, ComponentType } from "react"
import styled, { css } from "styled-components"

import { applySpacingProps, SpacingProps } from "../../spacing"

type Color = "purple" | "white"
type LinkProps = {
  target?: string
  href?: string
}
type StyledComponentsProps = {
  as?: string | ComponentType<any>
}
type Props = LinkProps & {
  color?: Color
  spacing?: SpacingProps
} & StyledComponentsProps

export const Button: FC<Props> = ({ color = "purple", children, ...rest }) => (
  <ButtonInner color={color} {...rest}>
    {children}
  </ButtonInner>
)

const purpleColorCSS = css`
  background: ${props => props.theme.colors.purple};
  color: ${props => props.theme.colors.white};

  &:hover {
    background: ${props => props.theme.colors.blueishPurple};
    box-shadow: 0px 11px 18px -7px rgba(87, 49, 169, 0.69);
  }
`
const whiteColorCSS = css`
  background: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.darkPurple};

  &:hover {
    background: ${props => props.theme.colors.borderGray};
  }
`

const ButtonInner = styled.div.withConfig<Props>({
  shouldForwardProp: prop => !["spacing", "color"].includes(prop),
})`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px 26px;
  border-radius: 34px;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  user-select: none;
  cursor: pointer;
  transition: ${props => props.theme.transitions.fastEase};

  ${props => props.color === "purple" && purpleColorCSS};
  ${props => props.color === "white" && whiteColorCSS};

  ${props => applySpacingProps(props.spacing)};
`
