import React from "react"
import styled from "styled-components"

import { Button } from "../../components/ui"
import {
  CalendarIcon,
  SquareBigSeb,
  SquareMediumSeb,
  SquareSmallSeb,
} from "../../components/ui/icons"
import SebastianInTriangleShapeImage from "../../assets/sebastian-introduction.jpg"

export const SebIntroduction = () => (
  <Section id="seb-introduction">
    <SquaresContainer>
      <SquaresContent>
        <StyledSquareBigSeb />
        <StyledSquareMediumSeb />
        <StyledSquareSmallSeb />
      </SquaresContent>
    </SquaresContainer>

    <ImageContainer>
      <SebImage src={SebastianInTriangleShapeImage} />
    </ImageContainer>

    <TextBlock>
      <Title>Ing. Sebastian Voráč</Title>

      <TitleDescription>
        Zakladatel Smoothweb.cz
        <br />
        Frontend Engineer {`&`} UX Researcher
        <br />
        {`sebastian.vorac@smoothweb.cz`}
      </TitleDescription>

      <Description>
        Pojďme spolu nezávazně probrat naší případnou spolupráci na krátkém
        15min online hovoru.
      </Description>

      <Button
        as="a"
        target="_blank"
        href="https://calendly.com/smoothweb-sebastian/15min"
      >
        <StyledCalendarIcon />
        Rezervovat online hovor
      </Button>
    </TextBlock>
  </Section>
)

const Section = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 ${props => props.theme.spaces.l};
  margin-bottom: ${props => props.theme.spaces.xxl};

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    flex-direction: row;
    justify-content: center;
  }
`

const SquaresContainer = styled.div`
  position: absolute;
  bottom: -150px;
  left: 0;
  width: 100%;
  max-width: 100vw;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
`

const SquaresContent = styled.div`
  position: relative;
  width: 100%;
  max-width: 1440px;
  color: ${props => props.theme.colors.lightBlue};
`

const StyledSquareBigSeb = styled(SquareBigSeb)`
  position: absolute;
  bottom: 0;
  right: 0;
`
const StyledSquareMediumSeb = styled(SquareMediumSeb)`
  display: none;
  position: absolute;
  top: -10px;
  right: 270px;

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    display: block;
  }
`
const StyledSquareSmallSeb = styled(SquareSmallSeb)`
  display: none;
  position: absolute;
  top: 120px;
  right: 320px;

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    display: block;
  }
`

const ImageContainer = styled.div`
  position: relative;
  max-width: 290px;
  margin-bottom: ${props => props.theme.spaces.l};

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    margin-right: ${props => props.theme.spaces.xxl};
    margin-bottom: 0;
  }
`

const SebImage = styled.img`
  width: 100%;
  height: auto;
`

const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    text-align: left;
    align-items: flex-start;
  }
`

const Title = styled.h2`
  margin-bottom: ${props => props.theme.spaces.m};
  color: ${props => props.theme.colors.dark};
  font-weight: bold;
  font-size: 18px;
  line-height: 149%;
  letter-spacing: -0.01em;

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    font-size: 20px;
    line-height: 27px;
  }
`

const TitleDescription = styled.p`
  margin-bottom: ${props => props.theme.spaces.xl};
  color: ${props => props.theme.colors.grayLight};
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.01em;

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
  }
`

const Description = styled.p`
  margin-bottom: ${props => props.theme.spaces.l};
  max-width: 410px;
  color: ${props => props.theme.colors.grayLight};
  font-size: 16px;
  line-height: 22px;

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    margin-bottom: ${props => props.theme.spaces.xl};
  }
`

const StyledCalendarIcon = styled(CalendarIcon)`
  width: 24px;
  height: auto;
  margin-right: ${props => props.theme.spaces.l};
`
