import { css } from "styled-components"

export const buttonCSS = css`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  overflow: hidden;
  user-select: none;
`
