import React from "react"
import styled from "styled-components"

import { TechStackItem } from "./components"
import stackShareLogoImage from "../../assets/stack-share-logo.png"

import { TECH_STACK_ITEMS } from "./constants/constants"

export const OurTechStack = () => (
  <Section id="our-tech-stack">
    <CircleIconContainer>
      <CircleIconContainerWrap>
        <CircleIcon />
      </CircleIconContainerWrap>
    </CircleIconContainer>

    <SectionContent>
      <Title>
        <NoWrapTablet>Používáme nejmodernější</NoWrapTablet>{" "}
        <NoWrap>technologie a nástroje</NoWrap>
      </Title>

      <TechStackContainer>
        {TECH_STACK_ITEMS.map(({ imageSrc, title }, i) => (
          <TechStackItem
            key={i}
            imageSrc={imageSrc}
            title={title}
          ></TechStackItem>
        ))}
      </TechStackContainer>

      <StackShareButton
        href="https://stackshare.io/smoothweb/smoothweb-stack"
        target="_blank"
      >
        <StackShareLogoImage src={stackShareLogoImage} />
      </StackShareButton>
    </SectionContent>
  </Section>
)

const Section = styled.section`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
`

const SectionContent = styled.div`
  position: relative;
  width: 100%;
  max-width: 1075px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 ${props => props.theme.spaces.l};
  margin: 0 auto;
  margin-bottom: ${props => props.theme.spaces.xxl};

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    margin-bottom: ${props => props.theme.spaces.xxxl};
  }
`

const CircleIconContainer = styled.div`
  position: absolute;
  top: -50px;
  right: 0;
  width: 100%;
  max-width: 100vw;
  display: flex;
  justify-content: center;
  pointer-events: none;
  overflow: hidden;

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    top: -135px;
  }
`

const CircleIconContainerWrap = styled.div`
  width: 100%;
  max-width: 1440px;
  display: flex;
  justify-content: flex-end;
`

const CircleIcon = styled.div`
  width: 278px;
  height: 278px;
  border: 50px solid ${props => props.theme.colors.lightBlue};
  border-radius: 50%;
  transform: translateX(35%);
  z-index: -1;

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    width: 438px;
    height: 438px;
    border-width: 70px;
  }
`

const Title = styled.h2`
  max-width: 610px;
  margin-bottom: ${props => props.theme.spaces.l};
  text-align: center;
  color: ${props => props.theme.colors.vine};
  font-weight: 600;
  font-size: 30px;
  line-height: 110%;
  letter-spacing: -0.03em;

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    align-self: flex-start;
    margin-bottom: ${props => props.theme.spaces.xxl};
    text-align: left;
    font-style: normal;
    font-weight: bold;
    font-size: 53px;
  }
`

const NoWrap = styled.span`
  white-space: nowrap;
`

const NoWrapTablet = styled.span`
  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    white-space: nowrap;
  }
`

const TechStackContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(131px, 1fr));
  justify-content: center;
  grid-gap: ${props => props.theme.spaces.l};
  margin-bottom: ${props => props.theme.spaces.l};

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    grid-template-columns: repeat(auto-fit, minmax(111px, 1fr));
  }
`

const StackShareButton = styled.a`
  align-self: flex-start;
  width: 150px;
`

const StackShareLogoImage = styled.img`
  width: 100%;
  height: auto;
`
