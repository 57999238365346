import React, { FC } from "react"
import styled from "styled-components"

import { Slider } from "../../components/slider/Slider"

export const References: FC = () => {
  return (
    <ReferencesSection id="references">
      <Slider />
    </ReferencesSection>
  )
}

const ReferencesSection = styled.section`
  width: 100%;
  max-width: 1340px;
  padding: 0 ${props => props.theme.spaces.l};
  padding-bottom: ${props => props.theme.spaces.xl};
  margin: ${props => props.theme.spaces.xxxl} auto 0;
  background: white;
  overflow: hidden;

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    padding-bottom: ${props => props.theme.spaces.xxl};
  }

  @media (min-width: ${props => props.theme.deviceBreakpoints.bigTablet}) {
    flex-direction: row;
    justify-content: center;
    padding-bottom: ${props => props.theme.spaces.xxxl};
  }
`
