import React, { FC } from "react"
import styled from "styled-components"

type Props = {
  imageSrc: string
  title: string
}

export const TechStackItem: FC<Props> = ({ imageSrc, title }) => (
  <TechStackItemInner>
    <Img src={imageSrc} />
    <Title>{title}</Title>
  </TechStackItemInner>
)

const TechStackItemInner = styled.div`
  width: 140px;
  height: 131px;
  padding: 14px ${props => props.theme.spaces.xl} ${props => props.theme.spaces.m};
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: 0px 8px 32px -11px rgba(107, 128, 244, 0.4);
  background: ${props => props.theme.colors.white};
  text-align: center;

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    width: 111px;
    padding: 14px 20px ${props => props.theme.spaces.m};
  }
`

const Img = styled.img`
  width: 100%;
  height: auto;
  margin-bottom: ${props => props.theme.spaces.m};
`

const Title = styled.p`
  color: ${props => props.theme.colors.grayLight};
  font-weight: 600;
  font-size: 12px;
  line-height: 120%;
  letter-spacing: -0.01em;
`
