import React from "react"
import styled, { css } from "styled-components"

import { ChevronLeftIcon, ChevronRightIcon } from "../../ui/icons"
import { buttonCSS } from "./styled/buttonCSS"

type Props = {
  side: "left" | "right"
  onClick: () => void
  className?: string
}

export const SliderButton: React.FC<Props> = ({ side, onClick, className }) => (
  <SliderButtonInner className={className} side={side} onClick={onClick}>
    {side === "left" ? <StyledChevronLeftIcon /> : <StyledChevronRightIcon />}
  </SliderButtonInner>
)

const SliderButtonInner = styled.div<{ side: "left" | "right" }>`
  ${buttonCSS}
  border: 1px solid ${props => props.theme.colors.borderGray};
  margin: 0 ${props => (props.side === "left" ? props.theme.spaces.m : 0)} 0
    ${props => (props.side === "right" ? props.theme.spaces.m : 0)};
  color: ${props => props.theme.colors.black};
  transition: ${props => props.theme.transitions.fastEase};

  &:hover {
    border-color: ${props => props.theme.colors.black};
  }

  @media (min-width: ${props => props.theme.deviceBreakpoints.bigTablet}) {
    width: 60px;
    height: 60px;
  }
`

const iconCSS = css`
  width: auto;
  height: 13px;

  @media (min-width: ${props => props.theme.deviceBreakpoints.bigTablet}) {
    height: 20px;
  }
`
const StyledChevronLeftIcon = styled(ChevronLeftIcon)`
  ${iconCSS}
`
const StyledChevronRightIcon = styled(ChevronRightIcon)`
  ${iconCSS}
`
